<template>
    <v-container>
            <div class="text-h5 font-weight-medium">
              Реквизиты ООО БПО
            </div>
            <v-col class="text-h6 font-weight-light">
              <b>Название организации:</b> Общество с ограниченной
              ответственностью Бюро путешествий «Ольга»<br />
              <v-divider></v-divider>
              <b>Юридический адрес/Местонахождения:</b> 109147, Россия, г.Москва,
              ул.Воронцовская, д.35Б, корп. 2, 4 этаж, II помещение, 7 комн.<br />
              <v-divider></v-divider>
              <b>Организационно правовая форма:</b> Общество с ограниченной
              ответственностью<br />
              <v-divider></v-divider>
              <b>Иностранное наименование:</b> LLC BPO<br />
              <v-divider></v-divider>
              <b>Фактический:</b> 127083, г. Москва, ул. Юннатов , д 18<br />
              <v-divider></v-divider>
              <b>Электронная почта:</b> agent@bpo.travel<br />
              <v-divider></v-divider>
              <b>Телефон/факс:</b> 8(495) 461-00-11<br />
              <v-divider></v-divider>
              <b>ОГРН:</b> 1037721009040<br />
              <v-divider></v-divider>
              <b>ИНН:</b> 7721252984<br />
              <v-divider></v-divider>
              <b>Генеральный директор</b> ИНН: 504401299131: Башина Марина
              Александровна (Решение № 01/21 от 22.01.2019 г., Приказ
              №2019/01/22-1 от 22.01.2019г.)
              <br />
            </v-col>
    </v-container>
  </template>
  <script>
  export default {
    name: "PaymentData",
    data() {
      return {
        
      };
    },
  };
  </script>
  
  <style scoped>
  .title {
    color: #004ea6;
  }
  </style>
  